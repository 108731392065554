<template>
<div>
    <div class="login-div forgot" style="background-size:contain">
        <div class="row">
            <div class="col-lg-8 col-12">
            </div>
            <div class="col-lg-4 col-12 login-container text-center">
                <img src="../../assets/images/logo.png" loading="lazy" height="70" class="mt-60" @click="sendToMarket">
                <div class="form-div">
                    <form action="" class="form text-center">
                        <h4 class="mt-2 head-font">{{tran.set_pass}}</h4>
                        <p class="mt-4 mb-4">{{tran.please_set_pass}}</p>
                        <div class="form-group mb-4">
                            <label class="form-label" @click.prevent="focusOnInput($event)" for="last">{{tran.email}}</label>
                            <div class="eye-div">
                                <input v-model="userQuery.email" class="form-input" type="email"     disabled/>
                                </div>
                            </div>
                            <div class="form-group mb-4">
                                <label class="form-label" @click.prevent="focusOnInput($event)" for="last">{{tran.password}}</label>
                                <div class="eye-div">
                                    <input v-model="password" class="form-input" :type="hideInput" autocomplete="false" @keydown.enter.prevent="checkEnterButton" />
                                    <i class="fa fa-eye-slash" @click="showInputText"  v-if="showEye"></i>
                                    <i class="fa fa-eye" @click="hideInputText" v-if="hideEye" ></i>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="form-label" @click.prevent="focusOnInput($event)" for="last">{{tran.retype_pass}}</label>
                                <div class="eye-div">
                                    <input v-model="retypePass" class="form-input" :type="hideInput" autocomplete="false" @keydown.enter.prevent="checkEnterButton" />
                                    <i class="fa fa-eye-slash" @click="showInputText"  v-if="showEye"></i>
                                    <i class="fa fa-eye" @click="hideInputText" v-if="hideEye" ></i>
                                </div>
                            </div>

                            <div class="form-group">
                                <button type="button" class="submit-btn mt-2" :class="{'fade-elem' : loader ||password == ''}" @click.prevent="sendPassword"> {{ loader ? '' :  tran.submit }}  <span class="loader mt-1" v-if="loader"></span></button>
                            </div>
                    </form>

                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import trans from '../../../translations'
import {
    userNotification
} from "../dashboard/mixins/notifyUser.js";
import {
    mapState
} from "vuex";
import http from "../../http/index";
import api from "../../apis/index";

export default {

    mixins: [userNotification],
    data() {

        return {
            hideEye: false,
            showEye: true,
            hideInput: 'password',
            tran: {},
            retypePass: '',
            password: '',
            userQuery: '',
            loader: false,
        }
    },
    computed: {
        ...mapState({

        }),
    },

    methods: {
  sendToMarket(){
            this.$router.push('/market')
        },

        sendForgotPassword() {

            this.$store.dispatch('auth/sendForgotPassword');
        },
        sendToLogin() {

            this.$router.push('/login')
        },

        focusOnInput(e) {

            e.target.parentNode.querySelector('.form-input').focus();
        },
        checkEnterButton() {
            if (event.keyCode === 13) {
                this.sendForgotPassword();
            }
        },

        sendPassword() {

            if (this.password !== this.retypePass) {
                this.$toast.warn({
                    title: this.tran.sorry,
                    icon: require("../../assets/images/cautionicon.png"),
                    timeOut: 5000,
                    message: "Password don't match",
                    position: "top center"
                });
                return
            }
            this.loader = true
            let vm = this

            let userDetail = {

                email: this.userQuery.email,
                token: this.userQuery.token,
                password: this.password
            }
            http.post(api.completeActivatorProcess, userDetail)
                .then(response => {
                    vm.loader = false

                    vm.$store.commit("auth/saveUserToken", response.data.data.token);
                    localStorage.setItem("token", response.data.data.token);
                    vm.$store.commit("auth/saveUserProfile", response.data.data);
                    fbq("track", "CompleteRegistration");

                    this.$router.push("/market");

                    try {

                        // Google Ads tracker
                        gtag("event", "conversion", {
                            send_to: "AW-703278616/uLvZCNOV9rMBEJjcrM8C"
                        });
                    } catch {

                    }

                })
                .catch(error => {
                    vm.loader = false
                    this.$helpers.log(error.response);
                });

        },
        hideInputText() {
            this.hideInput = 'password';
            this.showEye = true
            this.hideEye = false
        },
        showInputText() {
            this.hideInput = 'text';
            this.showEye = false
            this.hideEye = true
        },
    },
    mounted() {

        this.userQuery = localStorage.getItem("activatorQuery");

        this.userQuery = JSON.parse(this.userQuery);
        this.$helpers.log(this.userQuery, 'set Password')
    }
}
</script>

<style>

</style>

<template>
    <SetPassword/>
</template>

<script>
import SetPassword from '../../components/auth/SetPassword'
export default {

    components: {
        SetPassword
    }
}
</script>

<style>

</style>